<template>
  <div class="about_news">
    <div class="news_nav"></div>
    <div class="news_banner"></div>
    <h2 class="h2 brief">新闻资讯</h2>
    <div class="news_context">
      <template v-for="item in tableList">
        <div class="news_item" :key="item.id" @click="toNewDetails(item.id)">
          <img :src="getImgUrl(item)" class="img" />
          <div class="item_context">
            <div class="item_title">{{ item.title }}</div>
            <div class="item_text">{{ initText(item.contentTxt) }}</div>
            <div class="item_labelStr" v-if="item.labelStr">
              <template v-for="(label, index) in item.labelStr">
                <span :key="index">{{ label }}</span>
              </template>
            </div>
            <div class="item_time">
              <span class="time_day">{{ item.createTime[2] }}</span>
              <span>/ {{ item.createTime[0] }}-{{ item.createTime[1] }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="block">
        <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="dataCount" :page-size="5"> </el-pagination>
      </div>
    </div>
    <FootMain :isBanner="isBanner"></FootMain>
  </div>
</template>

<script>
import FootMain from '@/components/pc/commom/footmain/index.vue'
import { BaseURL } from '@/utils/request'
export default {
  components: { FootMain },
  data() {
    return {
      isBanner: false,
      pagination: {
        cur: 0,
        size: 5
      }
      // tableList: [],
      // dataCount: 0,
    }
  },
  methods: {
    query() {
      this.$store.dispatch('getNewsList', { page: this.pagination })
    },
    getImgUrl(item) {
      return `${BaseURL}static${item.mainImg}`
    },
    initText(text) {
      let height = text.length
      if (height < 70) return text
      let textArry = text.split('').slice(0, 70)
      let newText = textArry.join('')
      return `${newText}...`
    },
    handleCurrentChange(page) {
      this.pagination.cur = page
      this.query()
    },
    toNewDetails(id) {
      this.$router.push(`/newDetails?name=${id}`)
    }
  },
  computed: {
    tableList() {
      return this.$store.state.newsList
    },
    dataCount() {
      return this.$store.state.newsTotal
    }
  },
  created() {
    this.query()
  }
}
</script>

<style scoped lang="scss">
.news_nav {
  height: 3.947368rem;
  width: 100%;
  background-color: #7435f2;
}
.news_banner {
  height: 12.236842rem;
  width: 100%;
  background-image: url('~@/assets/img/pc/about/about_banner.jpg');
  background-size: 100% 100%;
}
.brief {
  margin-top: 1.315789rem;
  margin-bottom: 2.368421rem;
}
.news_context {
  width: 43.815789rem;
  margin: 0 auto;
}
.news_item {
  width: 36.8421rem;
  height: 5.263157rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0.921052rem 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  .img {
    width: 10.526315rem;
    height: 5.263157rem;
    transition: all 0.5s linear;
    &:hover {
      transform: scale(1.1);
    }
  }
  .item_context {
    flex: 1;
    height: 100%;
    padding: 0 0 0 1.052631rem;
    &:hover .item_time {
      color: #387dee;
    }
    &:hover .time_day {
      color: #387dee !important;
    }
    .item_title {
      font-size: 0.6rem;
      color: #000;
      line-height: 0.789473rem;
      margin-bottom: 0.131578rem;
    }
    .item_text {
      font-size: 0.473684rem;
      height: 1.578947rem;
      line-height: 0.789473rem;
      overflow: hidden;
      margin-bottom: 0.263157rem;
      color: #888;
    }
    .item_labelStr {
      margin-bottom: 0.131578rem;
      span {
        display: inline-block;
        min-width: 1.315789rem;
        max-width: 2.631578rem;
        height: 0.789473rem;
        line-height: 0.789473rem;
        text-align: center;
        background-color: #387dee;
        color: #fff;
        font-size: 0.368421rem;
        margin-right: 0.394736rem;
        border-radius: 0.105263rem;
        padding: 0 0.131578rem;
        overflow: hidden;
      }
    }
    .item_time {
      font-size: 0.6rem;
      .time_day {
        font-size: 1.263157rem;
        color: #000;
        padding-right: 0.263157rem;
      }
    }
  }
}
.block {
  width: 31.578947rem;
  margin: 0.263157rem auto 0;
}
</style>
<style scoped lang="scss">
.h2 {
  font-size: 1.315789rem;
  color: #333;
  text-align: center;
  position: relative;
  &::after {
    display: block;
    content: '';
    height: 0.368421rem;
    width: 3.815789rem;
    background-color: #06f410;
    border-radius: 0.18421rem;
    position: absolute;
    bottom: -0.657894rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
